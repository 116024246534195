<template>
  <div class="container">
    <div class="facility">
      <div>
        <a-input placeholder="请输入账号" type="text" v-model="username" />
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <a-button
        type="primary"
        @click="add"
        v-if="getJurisdictionList.indexOf('accountManagement-addBtn') > -1"
      >新增</a-button>
    </div>
    <!-- 表格 -->
    <ant-tabel
      :data="data"
      :columns="columns"
      :loading="loading"
      :status="status"
      :pagination="pagination"
      @changePaging="changePaging"
      :operationBtn="operationBtn"
      @operation="operation"
    ></ant-tabel>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div class="information">
        <a-form-model
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
        >
          <div v-for="(item, i) in addDemandtList" :key="i">
            <a-form-model-item :label="item.label" :prop="item.dataIndex">
              <div v-if="item.type == 'input'">
                <div>
                  <a-input
                    v-model="ruleForm[item.dataIndex]"
                    :placeholder="item.placeholder"
                    :type="
                      item.inputType && item.inputType == 'password'
                        ? 'password'
                        : 'text'
                    "
                  ></a-input>
                </div>
              </div>
              <div v-if="item.type == 'select'" class="select">
                <a-select
                  @change="(value) => handleChangeSelect(value, item)"
                  v-model="ruleForm[item.dataIndex]"
                  :placeholder="item.placeholder"
                >
                  <a-select-option
                    v-for="(d, index) in item.childrens"
                    :key="index"
                    :value="d.access"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </div>
              <div v-if="item.type == 'file'">
                <img v-if="imgShow" :src="qsCode" class="qsCode" alt srcset />
                <!-- 导入文件 -->
                <a-upload
                  v-else
                  name="file"
                  :file-list="ruleForm.qsCode"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  :customRequest="customRequest"
                  :showUploadList="{
                    showRemoveIcon: false,
                  }"
                >
                  <a-button>上传图片</a-button>
                </a-upload>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/index.js";
import AV from "leancloud-storage";
import storage from "../../storage/storage.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    width: 90,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  { title: "账号", dataIndex: "username", key: "username" },
  { title: "姓名", dataIndex: "name", key: "name" },
  { title: "手机号", dataIndex: "phone", key: "phone" },
  { title: "身份", dataIndex: "identity", key: "identity" },
  {
    title: "测试二维码",
    dataIndex: "qsCode",
    key: "qsCode",
    scopedSlots: { customRender: "qsCode" },
  },
  { title: "添加时间", dataIndex: "createdAt", key: "createdAt" },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      getJurisdictionList: "",
      flag: 1,
      username: "",
      imgShow: false,
      qsCode: "",
      ruleForm: {
        account: "",
        password: "",
        name: "",
        phone: "",
        access: undefined,
        qsCode: [],
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        access: [
          {
            required: true,
            message: "请选择身份",
            trigger: "change",
          },
        ],
      },
      title: "新增",
      visible: false,
      data,
      columns,
      loading: true,
      status: false,
      routes: [
        {
          path: "index",
          breadcrumbName: "Account management",
        },
      ],
      // 操作按钮
      operationBtn: [
        // { name: '编辑', type: 'compile' },
        // { name: '删除', type: 'deletes' },
      ],
      addDemandtList: [
        {
          type: "input",
          label: "账号:",
          dataIndex: "account",
          placeholder: "请输入账号",
        },
        {
          type: "input",
          label: "密码:",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        },
        {
          type: "input",
          label: "姓名:",
          dataIndex: "name",
          placeholder: "请输入名称",
        },
        {
          type: "input",
          label: "手机号:",
          dataIndex: "phone",
          placeholder: "请输入手机号",
        },
        {
          type: "select",
          label: "身份",
          dataIndex: "access",
          placeholder: "请选择身份",
          childrens: [],
        },
        {
          type: "file",
          label: "测试二维码",
          dataIndex: "qrCode",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
      users: "",
    };
  },
  created() {
    // 账号列表
    this.getAccount();
    // 权限身份
    this.getIdentity();
  },
  mounted() {
    this.users = storage.getItem("users");
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
      if (
        this.getJurisdictionList.indexOf("accountManagement-compileBtn") > 0
      ) {
        this.operationBtn.push({ name: "编辑", type: "compile" });
      }
      if (this.getJurisdictionList.indexOf("accountManagement-deleteBtn") > 0) {
        this.operationBtn.push({ name: "删除", type: "deletes" });
      }
    },
    getAccount() {
      api
        .getAccount({ pagination: this.pagination, username: this.username })
        .then((res) => {
          this.loading = false;
          this.data = [];
          res.userList.forEach((data) => {
            if (data.access_id) {
              data.identity = data.access_id.name;
            }
            data.createdAt = moment(data.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });
          this.pagination.total = res.count;
          this.data.push(...res.userList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIdentity() {
      api.getIdentity().then((res) => {
        res.forEach((item) => {
          // console.log(item.toJSON());
          let data = item.toJSON();
          this.addDemandtList[4].childrens.push({
            name: data.name,
            access: data.objectId,
          });
        });
      });
    },
    search() {
      this.getAccount(this.username);
      this.loading = true;
    },
    add() {
      if (this.addDemandtList[1].inputType != "password") {
        this.addDemandtList.splice(1, 0, {
          type: "input",
          label: "密码:",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        });
      }
      (this.ruleForm = {
        account: "",
        password: "",
        name: "",
        phone: "",
        access: undefined,
        qsCode: [],
      }),
        (this.visible = true);
      this.imgShow = false;
      // this.addDemandtList[1].type = 'input';
      this.flag = 1;
    },
    // 编辑
    compile(data) {
      if (this.addDemandtList[1].inputType == "password") {
        this.addDemandtList.splice(1, 1);
      }
      this.flag = 2;
      this.ruleForm.qsCode = [];
      this.imgShow = true;
      this.title = "编辑";
      this.visible = true;
      let { username, name, objectId, phone } = data;
      this.ruleForm.account = username;
      this.ruleForm.access = data.access_id.objectId;
      this.ruleForm.identity = data.access_id.objectId;
      this.ruleForm.name = name;
      this.ruleForm.objectId = objectId;
      this.ruleForm.phone = phone;
      this.ruleForm.password = undefined;
      // this.ruleForm.access = data.access_id.objectId;
      if (!data.qsCode) {
        this.imgShow = false;
      }
      if (data.qsCode) {
        this.ruleForm.qsCode.push({
          uid: data.qsCodeId,
          name: "qsCode",
          status: "done",
          url: data.qsCode,
        });
      }
      this.qsCode = data.qsCode;
    },
    //导入文件
    beforeUpload(file) {
      this.ruleForm.qsCode = [];
      const files = file.type === "image/jpeg" || file.type === "image/png";
      if (!files) {
        this.fileStatus = false;
        this.$message.error("请上传jpg或png的图片!");
        return false;
      }
      const fileUrl = new AV.File(file.name, file, "application/json");
      fileUrl.save().then(
        (file) => {
          let files = file.toJSON();
          // console.log(`文件保存完成。URL：${files.url}`);
          this.ruleForm.qsCode.push({
            uid: files.objectId,
            name: files.name,
            status: "done",
            url: files.url,
          });
        },
        (error) => {
          console.log(error);
          // 保存失败，可能是文件无法被读取，或者上传过程中出现问题
        }
      );
    },
    customRequest() { },
    handleChangeSelect() { },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.flag == 1) {
            this.ruleForm.code = this.users.exclusiveCode; // 医院专属码
            api
              .addAccount(this.ruleForm)
              .then(() => {
                this.visible = false;
                this.getAccount();
              })
              .catch((err) => {
                if (err.code == 202) {
                  this.$message.error("用户名已被占用!");
                  return;
                }
                if (err.code == 127) {
                  this.$message.error("无效手机号!");
                  return;
                }
                if (err.code == 214) {
                  this.$message.error("改手机号已被占用!");
                  return;
                }
                console.log(err.code);
              });
          } else {
            api.amendAccount(this.ruleForm).then((res) => {
              this.visible = false;
              console.log(res);
              this.getAccount();
            });
          }
          // alert('submit!');
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // console.log(this.ruleForm);
      // this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    //动态操作
    operation(type, data) {
      if (type == "compile") {
        this.compile(data);
      }
      if (type == "deletes") {
        this.deletes(data);
      }
    },

    // 删除
    deletes(data) {
      api.deleteAccount(data).then((res) => {
        console.log(res);
        this.getAccount();
      });
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.getAccount();
    },
  },
  watch: {},
};
</script>
<style scoped>
.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.facility .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}
.facility .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}
.select /deep/ .ant-select-selection {
  /* width: 185px !important; */
}
.qsCode {
  width: 80px;
  margin-left: 20px;
  height: 80px;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
